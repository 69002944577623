exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chinh-sach-bao-mat-js": () => import("./../../../src/pages/chinh-sach-bao-mat.js" /* webpackChunkName: "component---src-pages-chinh-sach-bao-mat-js" */),
  "component---src-pages-dia-chi-js": () => import("./../../../src/pages/dia-chi.js" /* webpackChunkName: "component---src-pages-dia-chi-js" */),
  "component---src-pages-dieu-khoan-js": () => import("./../../../src/pages/dieu-khoan.js" /* webpackChunkName: "component---src-pages-dieu-khoan-js" */),
  "component---src-pages-giay-phep-ve-sinh-an-toan-thuc-pham-js": () => import("./../../../src/pages/giay-phep-ve-sinh-an-toan-thuc-pham.js" /* webpackChunkName: "component---src-pages-giay-phep-ve-sinh-an-toan-thuc-pham-js" */),
  "component---src-pages-gioi-thieu-js": () => import("./../../../src/pages/gioi-thieu.js" /* webpackChunkName: "component---src-pages-gioi-thieu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ma-giam-gia-js": () => import("./../../../src/pages/ma-giam-gia.js" /* webpackChunkName: "component---src-pages-ma-giam-gia-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-menu-keo-nougat-js": () => import("./../../../src/pages/menu-keo-nougat.js" /* webpackChunkName: "component---src-pages-menu-keo-nougat-js" */),
  "component---src-pages-ship-hang-js": () => import("./../../../src/pages/ship-hang.js" /* webpackChunkName: "component---src-pages-ship-hang-js" */)
}

